import React from "react"
import {
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  Media,
} from "reactstrap"
import PerfectScrollbar from "react-perfect-scrollbar"
import { Button } from "../../../components/custom/button/Button"
import { Spinner } from "../../../components/custom/spinner/Spinner"
import _ from "lodash"
import { redirectTo } from "../../../history"

const GetModule = () => {
  const MODULES = JSON.parse(localStorage.getItem("MODULES"))
  let schoolName = "สำนักการศึกษา"
  if (localStorage.getItem("USER_TYPE") === "1") {
    schoolName = localStorage.getItem("USER_ORGANIZATION_NAME")
  } else if (
    localStorage.getItem("USER_TYPE") === "2" ||
    localStorage.getItem("USER_TYPE") === "3"
  ) {
    schoolName = "โรงเรียน" + localStorage.getItem("USER_SCHOOL_NAME")
  } else {
    schoolName = "สำนักการศึกษา"
  }

  function handleClick(path) {
    window.location = path
  }

  const getModules = () => {
    return (
      <>
        {!MODULES ? (
          <div className="d-flex justify-content-center my-3">
            <Spinner color="primary" />
          </div>
        ) : (
          MODULES.map((item, key) => (
            <div
              className="d-flex justify-content-between body-list-modules"
              key={key}
              onClick={() => {
                if (item.menuCode === "CO00") {
                  //Component
                  redirectTo("/modal")
                } else {
                  handleClick(item.firstRoleMenu.menuPath)
                }
              }}
            >
              <Media className="d-flex align-items-start">
                <Media left href="">
                  <div style={{ fontSize: 20, color: "#267659" }}>
                    <i className={item.icon} />
                  </div>
                </Media>
                <Media body>
                  <Media
                    heading
                    className="media-heading mb-0"
                    style={{ color: "#267659" }}
                    tag="h5"
                  >
                    {item.menuNameTH}
                  </Media>
                </Media>
              </Media>
            </div>
          ))
        )}
      </>
    )
  }
  return (
    <>
      <p
        className="d-none d-lg-block my-0 mr-2 align-self-center"
        style={{
          border: "1px solid #068F97",
          boxSizing: "border-box",
          borderRadius: "6px",
          padding: "8px 12px",
          color: "#068F97",
        }}
      >
        {schoolName}
      </p>
      <UncontrolledDropdown
        tag="li"
        className="dropdown-notification nav-item d-lg-block"
      >
        <DropdownToggle
          tag="a"
          className="nav-link nav-link-label pt-0 pb-0 pl-0 pr-0 ml-1"
        >
          <Button type="primary" height="40" width="150">
            เมนูหลัก
          </Button>
        </DropdownToggle>
        <DropdownMenu
          tag="ul"
          className="dropdown-menu-media dropdown-menu-left pt-0 mt-1"
        >
          <li className="dropdown-menu-header">
            <div className="dropdown-header mt-0">
              <h4 className="text-white">เมนูหลัก</h4>
            </div>
          </li>
          <PerfectScrollbar
            className="media-list overflow-hidden position-relative"
            options={{
              wheelPropagation: false,
            }}
          >
            {getModules()}
          </PerfectScrollbar>
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  )
}
export default GetModule
