import styled from 'styled-components'

export const DivPositionFixed = styled.div`
  position: fixed;
  background: rgba(255, 255, 255, 0.5);
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  z-index: 2000;
  div:first-child {
    overflow: unset !important;
  }

  .content-loading {
    --widthlogoloading: 80px;
    position: relative;
    top: calc(50% - var(--widthlogoloading));
    margin: auto;
    width: var(--widthlogoloading);
    height: var(--widthlogoloading);
    pointer-events: none;

    .loading-text {
      margin-top: 4px;
      text-align: center;
      font-size: 14px;
      line-height: 1;
    }
  }
`
