import React from 'react'
import { Theme } from '../../../styles'
import { Eclipse, Spinner as Spinners, Rolling, DualRing, Ball, Spin } from 'react-loading-io'
import { DivPositionFixed } from './PopupLoadingStyled.jsx'

export const PopupLoading = (props) => {
  return (
    <DivPositionFixed>
      <div className="content-loading">
        <Rolling size={64} width={10} color={Theme.Color_SeaGreen2} />
        <div className="loading-text">
          {(() => {
            if (typeof props.text === 'undefined') {
              return 'Loading'
            } else if (props.text) {
              return props.text
            }
          })()}
        </div>
      </div>
    </DivPositionFixed>
  )
}
