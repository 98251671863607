import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import SideMenuGroup from "./SideMenuGroup";
import { Badge, UncontrolledTooltip } from "reactstrap";
import { ChevronRight, Circle } from "react-feather";
import { history } from "../../../../../history";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_BY_PATH_MENU, GET_SHOW_MENU } from "../../../../../graphql/Query";
import navigationComponent from "../../../../../configs/navigationConfig.jsx";
import _ from "lodash";

const SideMenuContent = (props) => {
  const MODULES = JSON.parse(localStorage.getItem("MODULES"));
  let location = useLocation();
  let parentArr = [];
  const activePath = props.activePath;
  const pathname = _.split(location.pathname, "/");
  const redirectUnauthorized = () => {
    history.push("/misc/not-authorized");
  };

  const [navigationConfig, setNavigationConfig] = useState([]);
  const [collapsedMenuPaths, setCollapsedMenuPaths] = useState(props.collapsedMenuPaths);
  const [currentMenu, setCurrentMenu] = useState();
  const [state, setState] = useState({
    flag: true,
    isHovered: false,
    activeGroups: [],
    currentActiveGroup: [],
    tempArr: [],
  });

  const handleGroupClick = (id, parent = null, type = "") => {
    let open_group = state.activeGroups;
    let active_group = state.currentActiveGroup;
    let temp_arr = state.tempArr;

    // Active Group to apply sidebar-group-active class
    if (type === "item" && parent === null) {
      active_group = [];
      temp_arr = [];
    } else if (type === "item" && parent !== null) {
      active_group = [];
      if (temp_arr.includes(parent)) {
        temp_arr.splice(temp_arr.indexOf(parent) + 1, temp_arr.length);
      } else {
        temp_arr = [];
        temp_arr.push(parent);
      }
      active_group = temp_arr.slice(0);
    } else if (type === "collapse" && parent === null) {
      temp_arr = [];
      temp_arr.push(id);
    } else if (type === "collapse" && parent !== null) {
      if (active_group.includes(parent)) {
        temp_arr = active_group.slice(0);
      }
      if (temp_arr.includes(id)) {
        // temp_arr.splice(temp_arr.indexOf(id), 1)
        temp_arr.splice(temp_arr.indexOf(id), temp_arr.length);
      } else {
        temp_arr.push(id);
      }
    } else {
      temp_arr = [];
    }

    if (type === "collapse") {
      // If open group does not include clicked group item
      if (!open_group.includes(id)) {
        // Get unmatched items that are not in the active group
        let temp = open_group.filter(function (obj) {
          return active_group.indexOf(obj) === -1;
        });
        // Remove those unmatched items from open group
        if (temp.length > 0 && !open_group.includes(parent)) {
          open_group = open_group.filter(function (obj) {
            return !temp.includes(obj);
          });
        }
        if (open_group.includes(parent) && active_group.includes(parent)) {
          open_group = active_group.slice(0);
        }
        // Add group item clicked in open group
        if (!open_group.includes(id)) {
          open_group.push(id);
        }
      } else {
        // If open group includes click group item, remove it from open group
        open_group.splice(open_group.indexOf(id), 1);
      }
    }
    if (type === "item") {
      open_group = active_group.slice(0);
    }

    setState({
      ...state,
      activeGroups: open_group,
      tempArr: temp_arr,
      currentActiveGroup: active_group,
    });
  };

  const initRender = (parentArr) => {
    setState({
      ...state,
      activeGroups: parentArr.slice(0),
      currentActiveGroup: parentArr.slice(0),
      flag: false,
    });
  };

  useEffect(() => {
    initRender(parentArr[0] ? parentArr[0] : []);
    if (activePath !== props.activePath) {
      if (collapsedMenuPaths !== null) {
        setCollapsedMenuPaths(collapsedMenuPaths);
      }
      initRender(parentArr[0] ? parentArr[parentArr.length - 1] : []);
    }
  }, [collapsedMenuPaths]);

  const { data: dataMenu } = useQuery(GET_BY_PATH_MENU, { variables: { input: "/" + pathname[1] } });
  const [dataModule, { called: calledDataMenu, loading, data: loadDataMenu }] = useLazyQuery(GET_SHOW_MENU);
  const [dataFindMenu, { called: calledDataFindMenu, loading: loadingDataFindMenu, data: loadDataFindMenu }] =
    useLazyQuery(GET_BY_PATH_MENU);

  const mapChild = (child) =>
    child.map((item) => {
      return {
        id: item.menuCode,
        title: item.menuNameTH,
        type: "item",
        navLink: item.menuPath,
        icon: <Circle size={10} />,
      };
    });

  useEffect(() => {
    if (dataMenu) {
      setCurrentMenu(dataMenu);
      dataModule({
        variables: { input: dataMenu?.getByPathMenu?.menuParentCode },
      });
    } else {
      dataFindMenu({
        variables: {
          input: "/" + pathname[1] + "/" + pathname[2],
        },
      });
    }
  }, [dataMenu]);

  useEffect(() => {
    if (calledDataFindMenu && !loadingDataFindMenu && loadDataFindMenu) {
      setCurrentMenu(loadDataFindMenu);
      dataModule({
        variables: { input: loadDataFindMenu?.getByPathMenu?.menuParentCode },
      });
    }
  }, [loadDataFindMenu]);

  useEffect(() => {
    if (calledDataMenu && !loading) {
      if (currentMenu.getByPathMenu.menuCode === "CO00") {
        setNavigationConfig(navigationComponent);
      } else {
        if (loadDataMenu.showMenu.menuParentCode !== "0000") {
          dataModule({
            variables: {
              input: loadDataMenu.showMenu.menuParentCode,
            },
          });
        } else {
          const newMenuItem = [];
          MODULES.filter((item) => {
            if (item.menuCode === loadDataMenu.showMenu.menuCode) {
              item.level1.forEach((itmeMenuLevel1) => {
                newMenuItem.push({
                  id: itmeMenuLevel1.menuCode,
                  title: itmeMenuLevel1.menuNameTH,
                  type: !itmeMenuLevel1.level2.length === 0 ? "collapse" : "item",
                  icon: itmeMenuLevel1.icon ? <i className={`mr-1 ${itmeMenuLevel1.icon}`}></i> : <Circle size={10} />,
                  navLink: itmeMenuLevel1.menuPath,
                  badge: "primary",
                  children: mapChild(itmeMenuLevel1.level2),
                });
              });
            }
          });
          setNavigationConfig(newMenuItem);
        }
      }
    }
  }, [loadDataMenu]);

  const menuItems = navigationConfig.map((item) => {
    const CustomAnchorTag = item.type === "external-link" ? `a` : Link;
    // checks if item has groupheader
    if (item.type === "groupHeader") {
      return (
        <li className="navigation-header" key={`group-header-${item.groupTitle}`}>
          <span>{item.groupTitle}</span>
        </li>
      );
    }
    let renderItem = (
      <li
        className={classnames("nav-item", {
          "has-sub": item.type === "collapse",
          open: state.activeGroups.includes(item.id),
          "sidebar-group-active": state.currentActiveGroup.includes(item.id),
          hover: props.hoverIndex === item.id,
          active:
            (props.activeItemState.includes(item.navLink) && item.type === "item") ||
            (item.parentOf && item.parentOf.includes(props.activeItemState)),
          disabled: item.disabled,
        })}
        key={item.id}
        onClick={(e) => {
          e.stopPropagation();
          if (item.type === "item") {
            props.handleActiveItem(item.navLink);
            handleGroupClick(item.id, null, item.type);
            if (props.deviceWidth <= 1200 && item.type === "item") {
              props.toggleMenu();
            }
          } else {
            handleGroupClick(item.id, null, item.type);
          }
        }}
      >
        <CustomAnchorTag
          to={item.filterBase ? item.filterBase : item.navLink && item.type === "item" ? item.navLink : ""}
          href={item.type === "external-link" ? item.navLink : ""}
          className={`d-flex ${item.badgeText ? "justify-content-between" : "justify-content-start"}`}
          onMouseEnter={() => {
            props.handleSidebarMouseEnter(item.id);
          }}
          onMouseLeave={() => {
            props.handleSidebarMouseEnter(item.id);
          }}
          key={item.id}
          onClick={(e) => {
            return item.type === "collapse" ? e.preventDefault() : "";
          }}
          target={item.newTab ? "_blank" : undefined}
        >
          <div className="menu-text" id={`menu-${item.id}`}>
            {item.icon}
            <span className="menu-item menu-title">{item.title}</span>
            <UncontrolledTooltip placement="right" target={`menu-${item.id}`}>
              {item.title}
            </UncontrolledTooltip>
          </div>

          {item.badge ? (
            <div className="menu-badge">
              <Badge color={item.badge} className="mr-1" pill>
                {item.badgeText}
              </Badge>
            </div>
          ) : (
            ""
          )}
          {item.type === "collapse" ? <ChevronRight className="menu-toggle-icon" size={13} /> : ""}
        </CustomAnchorTag>
        {item.type === "collapse" ? (
          <SideMenuGroup
            group={item}
            handleGroupClick={handleGroupClick}
            activeGroup={state.activeGroups}
            handleActiveItem={props.handleActiveItem}
            activeItemState={props.activeItemState}
            handleSidebarMouseEnter={props.handleSidebarMouseEnter}
            activePath={activePath}
            hoverIndex={props.hoverIndex}
            initRender={initRender}
            parentArr={parentArr}
            triggerActive={undefined}
            currentActiveGroup={state.currentActiveGroup}
            permission={props.permission}
            currentUser={props.currentUser}
            redirectUnauthorized={redirectUnauthorized}
            collapsedMenuPaths={collapsedMenuPaths}
            toggleMenu={props.toggleMenu}
            deviceWidth={props.deviceWidth}
          />
        ) : (
          ""
        )}
      </li>
    );

    if (item.navLink && item.collapsed !== undefined && item.collapsed === true) {
      props.collapsedPath(item.navLink);
      props.collapsedMenuPaths(item.navLink);
    }

    //if (item.type === "collapse" || item.type === "external-link" || (item.type === "item" && item.permissions && item.permissions.includes(props.currentUser)) || item.permissions === undefined) {
    return renderItem;
    //} else if (item.type === "item" && item.navLink === activePath && !item.permissions.includes(props.currentUser)) {
    ///  return redirectUnauthorized()
    // }
  });

  return <React.Fragment>{menuItems}</React.Fragment>;
};

export default SideMenuContent;
